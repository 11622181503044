<template>
  <v-card min-height="400" flat class="xw-full">
    <!-- Change/Update Card -->
    <EachSetting
      v-if="subscription && subscription.status === 'BASIC MONTHLY'"
      title="Update card information"
      :with-expand="false"
      :subtitle="`Change or update your card used for subscribing to ${appName}.`"
    >
      <template v-slot:allright>
        <v-btn
          color="primary"
          depressed
          class="text-none px-8 xmax-w-[350px] xmx-auto"
          @click="$router.push({ name: 'app-update-information' })"
        >
          Update Card
        </v-btn>
      </template>
    </EachSetting>
    <!-- <pre>{{ subscription }}</pre> -->
    <!-- Set new users as paying -->
    <EachSetting
      title="Set new users as paying"
      subtitle="You can choose to add new users either automatically as paying users (with clocking allowed) or manually."
    >
      <template v-slot:left>
        <strong>Turn Off</strong>
        <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
          To manually add new users as paying (allowed clocking), choose this
          option.
        </div>
        <strong>Turn On</strong>
        <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
          Turn this on to automatically add new users as paying (allowed
          clocking).
        </div>
      </template>
      <template v-slot:allright>
        <div
          class="xcol-span-1 xflex xflex-row xjustify-center xgap-x-4 xitems-center xp-2"
        >
          <span class="xfont-semibold">Off</span>
          <v-switch
            :disabled="!$can.and('update_team_preferences')"
            :true-value="1"
            :false-value="0"
            color="#19CB40"
            v-model="auto_add_users"
            @change="handleChange"
            inset
          ></v-switch>
          <span class="xfont-semibold">On</span>
        </div>
      </template>
    </EachSetting>
    <!-- <v-divider /> -->
    <v-card-title
      :class="[tabletUp ? 'xflex-row' : 'xflex-col']"
      class="xflex xflex-nowrap xgap-[0.5em] xjustify-end xitems-center"
    >
      <v-btn
        :loading="saving"
        color="primary"
        :block="smDown"
        @click="saveChanges"
        :disabled="!payings.length || saving"
        class="text-none px-5"
      >
        <v-icon left v-if="mdUp">mdi-content-save</v-icon>
        Save Changes
      </v-btn>
      <SyncToSlack
        :block="smDown"
        v-if="user"
        :team-id="user.team_id"
      ></SyncToSlack>
    </v-card-title>

    <app-table :loading="fetching" :headings="headers" :items="allusers">
      <template v-slot:name="{ item }">
        <avatar size="35" :user="item" with-name></avatar>
      </template>
      <template v-slot:title="{ item }">
        {{ item.job_title }}
      </template>
      <template v-slot:role="{ item }">
        {{ item.role ? item.role.normalize_name : "Slack User" }}
      </template>
      <template v-slot:deleted_at="{ item }">
        <icon-tooltip
          v-if="item.deleted_at"
          color="error"
          class="xmx-auto"
          tip="User is inactive"
          icon="mdi-account-remove"
        ></icon-tooltip>
        <icon-tooltip
          v-else
          class="xmx-auto"
          color="success"
          tip="User is active"
          icon="mdi-account-check"
        ></icon-tooltip>
      </template>
      <template v-slot:is_paying="{ item }">
        <v-switch
          inset
          :disabled="!!item.deleted_at"
          class="xmy-0"
          color="#19CB40"
          v-model="payings"
          :value="item.id"
          on-icon="mdi-clipboard-check"
          off-icon="mdi-clipboard-outline"
          :true-value="item.id"
          :false-value="null"
          hide-details="auto"
        ></v-switch>
      </template>
    </app-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachSetting from "../../Settings/components/EachSetting.vue";
export default {
  components: { EachSetting },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", [
      "workspace",
      "allusers",
      "subscription",
      "syncing",
      "fetching",
    ]),
    ...mapGetters("workspaceconfigs", ["getByKey"]),
    currentPayingCount() {
      return this.allusers.filter((i) => i.is_paying).length;
    },
    payload() {
      return {
        ids: this.payings,
      };
    },
    auto_add_users: {
      get() {
        return this.getByKey("auto_add_users", 0);
      },
      set(val) {
        this.set_config({ key: "auto_add_users", value: val });
      },
    },
    headers() {
      return [
        { text: "Name", value: "name", sortable: true, align: "left" },
        {
          text: "Title",
          value: "title",
          sortable: true,
          align: "left",
          width: 200,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
          align: "left",
        },
        { text: "Main Role", value: "role", sortable: false, align: "left" },
        {
          text: "Active",
          value: "deleted_at",
          sortable: true,
          width: 100,
          align: "left",
        },
        {
          text:
            this.subscription && this.subscription.status === "EXEMPTED"
              ? "Allow Clocking"
              : "Add To Paying",
          value: "is_paying",
          sortable: false,
          width: 120,
          tdClass: "xtext-center",
        },
      ];
    },
  },
  watch: {
    allusers: {
      handler: function (val) {
        this.payings = !val
          ? []
          : _.cloneDeep(val)
              .filter((i) => i.is_paying)
              .map((j) => j.id);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      saving: false,
      payings: [],
      showInactive: false,
    };
  },
  created() {
    this.fetchWorkspaceAllUsers(this.user.team_id);
    this.fetchWorkspaceConfigs(this.user.team_id);
  },
  methods: {
    ...mapMutations(["set_step1"]),
    ...mapMutations("workspaceconfigs", ["set_config"]),
    ...mapActions("workspace", [
      "fetchWorkspaceAllUsers",
      "fetchWorkspaceStatistics",
    ]),
    ...mapActions("workspaceconfigs", [
      "updateWorkspaceConfig",
      "fetchWorkspaceConfigs",
    ]),
    handleChange(val) {
      this.updateWorkspaceConfig({
        id: this.user.team_id,
        payload: { key: "auto_add_users", value: val },
        cb: () => {
          this.appToast("Success! Configuration updated", "success");
        },
      });
    },
    saveChanges() {
      this.saving = true;
      this.$axios
        .post(`api/workspaces/users/payings`, this.payload)
        .then(({ data }) => {
          this.appToast("Success! Changes were saved.", "success");
          this.fetchWorkspaceAllUsers(this.workspace.id);
          this.fetchWorkspaceStatistics(this.workspace.id);
          this.set_step1(false);
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
